import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "finzen-logo" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <ImageWrapper>
        <StyledImage fluid={data.file.childImageSharp.fluid} />
      </ImageWrapper>
    </Layout>
  )
}

const ImageWrapper = styled.div`
  margin-top: 200px;
`

const StyledImage = styled(Img)`
  margin: auto;
  width: 150px;
  height: 150px;
`

export default IndexPage
